<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-21 11:40:43
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-02 15:25:20
-->
<template>
  <div class="page404">
    <img class="icon" src="@/assets/empty.png" alt srcset />
    <div class="remark">{{ remark }}</div>
  </div>
</template>
<script>
export default {
  name: "Error",
  props: {
    remark: String,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.page404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    width: 324px;
    height: 188px;
  }
  .remark {
    font-size: 17px;
    color: rgba($color: #000000, $alpha: 0.4);
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    margin-top: 50px;
  }
}
</style>
