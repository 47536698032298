<!--
 * @FileDescription: 
 * @Author: zhuxin xu
 * @Date: 2022-12-07 10:31:52
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-02 15:24:02
-->
<template>
  <div class="error_main"><Error class="error" :remark="remark || $t('error.ErrorTip')" /></div>
</template>

<script>
import Error from "./module/Errorpc.vue";
export default {
  components: { Error },
  props: {
    remark: String,
  },
  data() {
    return {
      expand: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.error_main {
  display: flex;
  height: 100vh;
  flex-direction: column;
  .error {
    flex: 1;
  }
}
</style>
